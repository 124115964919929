import styled, { css } from 'styled-components';

import { TooltipColor, TooltipProps } from '@/shared/ui/tooltip/tooltip.types';

export const TooltipPopup = styled.div<
	Pick<
		TooltipProps,
		| 'direction'
		| 'position'
		| 'flexDirection'
		| 'maxWidth'
		| 'textAlign'
		| 'fontSize'
		| 'width'
		| 'padding'
		| 'color'
		| 'fontWeight'
	>
>`
	display: flex;
	flex-direction: ${({ flexDirection }) => flexDirection};
	text-align: ${({ textAlign }) => textAlign};
	max-width: ${({ maxWidth }) => maxWidth};
	flex-wrap: ${({ maxWidth }) => (maxWidth ? 'wrap' : 'initial')};
	position: ${({ position }) => position};
	left: ${({ position }) => position === 'absolute' && '50%'};
	transform: ${({ position }) => (position === 'absolute' ? 'translateX(-50%)' : 'translateX(15%)')};
	font-size: ${({ fontSize }) => fontSize};
	font-weight: ${({ fontWeight }) => fontWeight};
	padding: ${({ padding }) => padding};
	border-radius: 2px;
	width: ${({ width }) => width};
	background: ${({ theme: { tooltip } }) => tooltip.background};
	color: ${({ color, theme: { tooltip } }) => tooltip.color[color as TooltipColor]};
	border: ${({ theme: { tooltip } }) => tooltip.border};
	z-index: ${({ theme: { zIndex } }) => zIndex.modal};
	box-sizing: content-box;

	${({ direction }) =>
		direction === 'top' &&
		css`
			top: 0;
			transform: translateY(-100%) translateX(-50%);
		`}

	${({ direction }) =>
		direction === 'bottom' &&
		css`
			top: 100%;
		`}

	@media ${({ theme: { media } }) => media.tabletAndMobile} {
		width: ${({ width }) => width};
	}
`;

export const SplittedTooltip = styled.div`
	white-space: normal;
`;
