import { UserRole } from '@/entities/user';
import DataSvg from '@/shared/assets/icons/sidebar/aggregate-data.svg';
import ClockSvg from '@/shared/assets/icons/sidebar/clock.svg';
import DashboardSvg from '@/shared/assets/icons/sidebar/dashboard.svg';
import HistoricalSvg from '@/shared/assets/icons/sidebar/historical.svg';
import HistoryAnalysisSvg from '@/shared/assets/icons/sidebar/history-analysis.svg';
import ManagementSvg from '@/shared/assets/icons/sidebar/management.svg';
import MapSvg from '@/shared/assets/icons/sidebar/map.svg';
import OilAnalysisSvg from '@/shared/assets/icons/sidebar/oil-analysis.svg';
import OverviewSvg from '@/shared/assets/icons/sidebar/overview.svg';
import PlantTrackSvg from '@/shared/assets/icons/sidebar/select-plant.svg';
import ShipSvg from '@/shared/assets/icons/sidebar/select-ship.svg';
import VirtualCockpitSvg from '@/shared/assets/icons/sidebar/virtual-cockpit.svg';
import YachtTrackSvg from '@/shared/assets/icons/sidebar/yacht-track.svg';
import { AppRoutes } from '@/shared/constants/app-routes.constant';

import { NavigationItem } from '../types/navigation-item.interface';

export const navigationList: NavigationItem[] = [
	{
		title: 'sidebar.navigation.data.title',
		routes: [
			{
				Svg: OverviewSvg,
				label: 'sidebar.navigation.data.multi.yacht',
				href: AppRoutes.MultiYachtView,
				canShow: (role) => role !== UserRole.SingleBoat,
				dynamicHref: ({ multiView }) => multiView,
				dynamicLabel: ({ sidebarMultiView }) => sidebarMultiView,
			},
			{
				Svg: ShipSvg,
				label: 'sidebar.navigation.data.yacht',
				href: AppRoutes.YachtView,
				dynamicHref: ({ singleView }) => singleView,
				dynamicLabel: ({ sidebarSingleView }) => sidebarSingleView,
				dynamicSvg: (isPlant) => (isPlant ? PlantTrackSvg : ShipSvg),
			},
			{ Svg: MapSvg, label: 'sidebar.navigation.data.map', href: AppRoutes.Map },
			{
				Svg: YachtTrackSvg,
				label: 'sidebar.navigation.data.yachtTrack',
				href: AppRoutes.YachtTrack,
				canShow: (_, isPlant) => !isPlant,
			},
			{ Svg: VirtualCockpitSvg, label: 'sidebar.navigation.data.virtual', href: AppRoutes.VirtualCockpit },
			{ Svg: OilAnalysisSvg, label: 'sidebar.navigation.data.oil.analysis', href: AppRoutes.OilAnalysis },
			{
				Svg: HistoricalSvg,
				label: 'sidebar.navigation.data.historical',
				href: AppRoutes.HistoricalData,
				canShow: () => DISPLAY_RESET_PASSWORD,
			},
			{
				Svg: DataSvg,
				label: 'sidebar.navigation.data.aggregate',
				href: AppRoutes.AggregadeData,
				canShow: (_, isPlant) => !isPlant,
			},
			{ Svg: DashboardSvg, label: 'sidebar.navigation.data.dashboard', href: AppRoutes.Dashboard },
		],
	},
	{
		title: 'sidebar.navigation.alarm.title',
		routes: [
			{ Svg: ClockSvg, label: 'sidebar.navigation.alarm.real', href: AppRoutes.AlarmRealTime },
			{
				Svg: HistoryAnalysisSvg,
				label: 'sidebar.navigation.alarm.history.analysis',
				href: AppRoutes.AlarmHistoryAnalysis,
			},
			{ Svg: HistoricalSvg, label: 'sidebar.navigation.alarm.history', href: AppRoutes.AlarmHistory },
			{ Svg: DashboardSvg, label: 'sidebar.navigation.alarm.dashboard', href: AppRoutes.AlarmDashboard },
			{ Svg: ManagementSvg, label: 'sidebar.navigation.alarm.management', href: AppRoutes.AlarmManagement },
		],
	},
	{
		title: 'sidebar.navigation.status.title',
		dynamicTitle: (isPlant) => (isPlant ? 'sidebar.navigation.plant.status.title' : 'sidebar.navigation.status.title'),
		routes: [
			{ Svg: ClockSvg, label: 'sidebar.navigation.status.real', href: AppRoutes.StatusRealTime },
			{ Svg: HistoricalSvg, label: 'sidebar.navigation.status.history', href: AppRoutes.StatusHistory },
			{ Svg: DashboardSvg, label: 'sidebar.navigation.status.dashboard', href: AppRoutes.StatusDashboard },
		],
	},
];
